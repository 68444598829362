import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { PRIVACY_POLICY_PAGE_URL } from "../utils/constants"

const PrivacyPage = () => {
  useEffect(() => {
    navigate(`${PRIVACY_POLICY_PAGE_URL}`, { replace: true })
  }, [])

  return <></>
}

export default PrivacyPage
